import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { ExpandMore, Home, Menu } from "@material-ui/icons"
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useWindowScroll } from "react-use"
import { useStyles } from "./styles"

const Navbar = () => {
  const initialOpenState = {
    tuInstituto: false,
    serviciosMedicos: false,
    prestaciones: false,
    transparenciaDifusion: false,
    serviciosOnline: false,
    informacionFinanciera: false,
  }

  const classes = useStyles()
  const wrapperRef = useRef(null)
  const scroll = useWindowScroll()
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
  const [open, setOpen] = useState(initialOpenState)

  const useOutside = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) =>
        ref.current && !ref.current.contains(event.target)
          ? setOpen(initialOpenState)
          : null

      document.addEventListener("mousedown", handleClickOutside)
      return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [ref])
  }

  useOutside(wrapperRef)

  return (
    <AppBar
      elevation={0}
      position={scroll.y >= 34 ? "fixed" : "static"}
      style={{ zIndex: 110 }}
      component="nav"
      ref={wrapperRef}
    >
      <Toolbar className={classes.toolbar} style={{ zIndex: 110 }}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={2}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                style={{ display: "inline" }}
                src={require("../../assets/images/logo/logo-isset-blanco.svg")}
                alt="Logo ISSET"
                className={classes.logo}
              />
            </Link>
          </Grid>
          <Grid
            ref={wrapperRef}
            item
            xs={10}
            className={classes.burger}
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          >
            <IconButton>
              <Menu style={{ color: "#fff" }} />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            lg={10}
            container
            className={classes.links}
            style={{ display: isOpenMenu ? "flex" : "none" }}
          >
            <Grid item xs={12} lg style={{ flexGrow: 0 }}>
              <Link
                to="/"
                className={classes.home}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <Home style={{ marginRight: 10 }} />
                <Typography variant="body2">Inicio</Typography>
              </Link>
            </Grid>

            <Grid item xs={12} lg container className={classes.menu}>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                className={classes.dropdown}
                onClick={() =>
                  setOpen({
                    ...initialOpenState,
                    tuInstituto: !open.tuInstituto,
                  })
                }
              >
                <Typography variant="body2">Tu instituto</Typography>
                <ExpandMore />
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={`${classes.submenu} ${classes.submenuCard}`}
                style={{ display: open.tuInstituto ? "flex" : "none" }}
              >
                <Grid item>
                  <Link to="/tu-instituto/mensaje">Mensaje del encargado de despacho de la Dirección General</Link>
                </Grid>
                <Grid item>
                  <Typography variant="body2">¿Quienes somos?</Typography>
                  <Grid
                    container
                    direction="column"
                    className={classes.submenu}
                  >
                    <Grid item>
                    <Link to="/tu-instituto/estructura-organica">
                        Estructura organíca
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tu-instituto/marco-juridico">
                        Marco jurídico e institucional
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tu-instituto/mision">
                        Filosofía institucional
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/tu-instituto/historia">
                        Historia del ISSET
                      </Link>
                    </Grid>
                    
                    <Grid item>
                      <Link to='/tu-instituto/organos-colegiados'>Órganos colegiados</Link>
                    </Grid>
                    <Grid item>
                  <Link to="/transparencia-difusion/comites">
                    Comités / Comisiones Institucionales
                  </Link>
                </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Link to="/tu-instituto/servicios">
                    Servicios y trámites del ISSET
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/tu-instituto/galeria">
                    Galería fotográficas 
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/tu-instituto/faq">Preguntas frecuentes</Link>
                </Grid>
                <Grid item>
                  <Link to="/tu-instituto/contacto">Contacto</Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              lg
              container
              alignItems="center"
              className={classes.menu}
            >
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                className={classes.dropdown}
                onClick={() =>
                  setOpen({
                    ...initialOpenState,
                    serviciosMedicos: !open.serviciosMedicos,
                  })
                }
              >
                <Typography variant="body2">Servicios médicos</Typography>
                <ExpandMore />
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={`${classes.submenu} ${classes.submenuCard}`}
                style={{ display: open.serviciosMedicos ? "flex" : "none" }}
              >
                <Grid item>
                  <Link to="/servicios-medicos/domicilios-umfs">
                    Centros de atención respiratoria y unidades de medicina
                    familiar
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/servicios-medicos/cem">
                    Jefaturas de servicios del centro de especialidades médicas
                  </Link>
                </Grid>
                <Grid item>
                      <a
                        href="https://presmed.isset.gob.mx/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portal de citas médicas
                      </a>
                    </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              lg
              container
              alignItems="center"
              className={classes.menu}
            >
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                className={classes.dropdown}
                onClick={() =>
                  setOpen({
                    ...initialOpenState,
                    prestaciones: !open.prestaciones,
                  })
                }
              >
                <Typography variant="body2">Servicios en línea</Typography>
                <ExpandMore />
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={`${classes.submenu} ${classes.submenuCard}`}
                style={{ display: open.prestaciones ? "flex" : "none" }}
              >
                <Grid item>
                  <Typography variant="body2">A derechohabientes</Typography>
                  <Grid
                    container
                    direction="column"
                    className={classes.submenu}
                  >
                    <Grid item>
                      <Link to="/servicios-enlinea/portal-creditos">
                        Portal de Créditos
                      </Link>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://tramites.isset.gob.mx:6001/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Prestaciones Socioeconómicas
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://presmed.isset.gob.mx/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portal de citas médicas
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://recibo.isset.gob.mx:6009/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portal para trabajadores del ISSET
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body2">A pensionados</Typography>
                  <Grid
                    container
                    direction="column"
                    className={classes.submenu}
                  >
                    <Grid item>
                      <a
                        href="https://portalpensionados.isset.gob.mx/portalPensionadosJubilados/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portal de pensionados
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://isset.gob.mx:8443/pcir/pension_alimentaria_form.jsp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pensión alimentaria
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body2">Entes públicos</Typography>
                  <Grid
                    container
                    direction="column"
                    className={classes.submenu}
                  >
                    <Grid item>
                      <a
                        href="https://entespublicos.isset.gob.mx:8443/isset/faces/iniciarSesion"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portal de entes del ISSET
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://presmed.isset.gob.mx/licencia-medica"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Verificación de licencias médicas
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://presmed.isset.gob.mx/constancias-cuidados-menor"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Verificación de constancias de cuidados al menor
                      </a>
                    </Grid>
                    <Grid item>
                      <Link to="/servicios-medicos/accidente-trabajo">
                        Notificación de accidente de trabajo
                      </Link>
                    </Grid>                    
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body2">A proveedores</Typography>
                  <Grid
                    container
                    direction="column"
                    className={classes.submenu}
                  >
                    <Grid item>
                      <Link to="/proveedores">
                        Portal de proveedores del ISSET
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Link to="/tu-instituto/servicios">
                    Buscador de trámites y servicios del ISSET
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              lg
              container
              alignItems="center"
              className={classes.menu}
            >
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                className={classes.dropdown}
                onClick={() =>
                  setOpen({
                    ...initialOpenState,
                    transparenciaDifusion: !open.transparenciaDifusion,
                  })
                }
              >
                <Typography variant="body2">
                  Transparencia y difusión
                </Typography>
                <ExpandMore />
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={`${classes.submenu} ${classes.submenuCard}`}
                style={{
                  display: open.transparenciaDifusion ? "flex" : "none",
                  right: 0,
                }}
              >
                <Grid item>
                  <Link to="/transparencia-difusion/sevac">
                    Armonización contable
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/transparencia-difusion/informe-trimestral-indicadores">
                    Informe trimestral indicadores
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/transparencia-difusion/planeacion">
                    Información estadística
                  </Link>
                </Grid>  
                <Grid item>
                  <Link to="/transparencia-difusion/lineamiento-criterio">
                    Lineamientos y/o criterios de programación presupuestaria ISSET
                  </Link> 
                </Grid>            
                <Grid>
                  <Link to="/transparencia-difusion/adquisiciones-presupuestos">
                  Programa anual de adquisiciones
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/transparencia-difusion/bienes">
                    Padrón de bienes
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/transparencia-difusion/sia">
                    Sistema Institucional de Archivos (SIA)
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/transparencia-difusion/calendario-pensiones">
                    Calendario de pago de pensionados
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/transparencia-difusion/informes">
                    Informes anuales de labores
                  </Link>
                </Grid>
               {/*
                <Grid item>
                  <a
                    href="http://www.isset.gob.mx/static/docs/transparencia-difusion/2_programa-institucional-isset-2020.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Programa institucional ISSET 2019 - 2024
                  </a>
                </Grid>
              */}
                <Grid item>
                  <Link to="/transparencia-difusion/programa-agenda-mejora-regulatoria">
                    Programa y agenda de mejora regulatoria
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/transparencia-difusion/colaboracion-academicos">
                    Convenios de colaboración académica
                  </Link>
                </Grid>
                {/* <Grid item>
                  <Link to="/transparencia-difusion/comite-comisiones">
                    Comité / Comisiones Institucionales
                  </Link>
                </Grid> */}             
                <Grid item>
                  <Link to="/transparencia-difusion/catalogo-cuadros-basicos">
                    Catálogos y cuadros básicos
                  </Link>
                </Grid>
                {/*<Grid item>
                  <a
                    href="http://www.isset.gob.mx/static/docs/transparencia-difusion/3_programa-anual-ene-mar-2020.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Programa anual de adquisiciones 2020
                  </a>
              </Grid>*/}
                <Grid item>
                  <a
                    href="https://www.isset.gob.mx/static/docs/transparencia-difusion/4_aviso-privacidad.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Aviso de privacidad
                  </a>
                </Grid>
              </Grid>
            </Grid>

              <Grid item 
              xs={12} 
              lg 
              container 
              className={classes.menu}>
                <Grid item
                xs={12}
                container
                alignItems="center"
                className={classes.dropdown}
                >
                  <Link
                to="/informacion-financiera"
                className={classes.home}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}                
              >                
                <Typography variant="body2">Información Financiera</Typography>
              </Link>
                </Grid>                
              </Grid>

            {/* fin */}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
