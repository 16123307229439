import { Grid, Theme, Typography, createStyles, makeStyles, useTheme } from '@material-ui/core';
import { mdiEmail, mdiFacebook, mdiTwitter, mdiYoutube } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { disable_propaganda, veda_electoral } from '../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            padding: `2px ${theme.spacing(3)}px`
        },
        followText: {
            display: 'none',
            marginBottom: 2.5,
            [theme.breakpoints.up(780)]: {
                display: 'block'
            }
        },
        link: {
            fontWeight: 600,
            textDecoration: 'none',
            color: theme.palette.primary.main
        }
    })
);

const MediaInfo = () => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <>
          
            {veda_electoral ? (
                <div style={{ width: '100%', backgroundColor: 'white', textAlign: 'center' }}>
                    <Typography style={{ fontWeight: 'bold' }}>En cumplimiento a las disposiciones legales y con motivo del proceso de Consulta Popular, algunos contenidos no estarán disponibles.</Typography>
                </div>
            ) : null}
            
            {disable_propaganda ? (
                <div style={{ width: '100%', backgroundColor: 'white' }}>
                    <Typography variant='caption' style={{ fontWeight: 'bold' }}>En cumplimiento a los artículos 35, fracción IX, numeral 7, párrafo cuarto, de la Constitución Política de los Estados Unidos Mexicanos; y 33, párrafo quinto, de la Ley Federal de Revocación de Mandato, desde el 04 de febrero al 10 de abril de 2022, derivado de la Consulta de Revocación de Mandato, se suspenden las actividades de difusión de propaganda institucional del Gobierno del Estado de Tabasco, con excepción de las relativas a servicios educativos, salud y las necesarias para la protección civil en caso de emergencia.</Typography>
                </div>
            ) : null}
            <Grid container justify="space-between" alignItems="center" className={classes.bar}>
                <Grid item xs={6}>
                    <a href="https://tabasco.gob.mx/" target="_blank" rel="noopener noreferrer" className={classes.link}>tabasco.gob.mx</a>
                </Grid>
                <Grid item xs={6} spacing={1} container justify="flex-end" alignItems="center">
                    <Grid item className={classes.followText}>
                        <Typography variant="caption">Siguenos en:</Typography>
                    </Grid>
                    <Grid item>
                        <a href="https://www.facebook.com/issetTabasco" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiFacebook} color={theme.palette.primary.main} size={1.05} />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://twitter.com/Isset_Tab" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiTwitter} color={theme.palette.primary.main} size={1.05} />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.youtube.com/channel/UCl6tcQE_bnIRDQz35ASH69Q" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiYoutube} color={theme.palette.primary.main} size={1.05} />
                        </a>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>|</Typography>
                    </Grid>
                    <Grid item className={classes.followText}>
                        <Typography variant="caption">Correo institucional:</Typography>
                    </Grid>
                    <Grid item>
                        <a href="https://correo.isset.gob.mx/" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiEmail} color={theme.palette.primary.main} size={1.05} />
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MediaInfo;