import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    height: 50,
    minHeight: 50,
    maxHeight: 50
  },
  burger: {
    display: 'block',
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  logo: {
    width: 75,
    display: 'block',
    verticalAlign: 'middle'
  },
  home: {
    color: theme.palette.text.primary,
    [theme.breakpoints.up('lg')]: {
      color: '#fff'
    }
  },
  links: {
    display: 'none',
    [theme.breakpoints.down(1279)]: {
      borderRadius: 4,
      backgroundColor: '#fff',
      position: 'absolute',
      top: 50,
      boxShadow: theme.shadows[1],
      maxWidth: '100%',
      flexBasis: '100%',
      marginLeft: -24,
      padding: theme.spacing(2),
      flexDirection: 'column',
      maxHeight: 530,
      flexWrap: 'nowrap',
      overflowY: 'auto'
    },
    [theme.breakpoints.down(600)]: {
      marginLeft: -16,
    },
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      display: 'flex !important',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  },
  dropdown: {
    cursor: 'pointer',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('lg')]: {
      color: '#fff'
    }
  },
  menu: {
    position: 'relative',
    color: theme.palette.text.primary,
    flexGrow: 'initial',
    maxWidth: 'initial',
    [theme.breakpoints.up('lg')]: {
      '& > div': {
        marginLeft: 25,
        '&:first-child()': {
          marginLeft: 0
        }
      },
    },
    [theme.breakpoints.down(1279)]: {
      '& > div': {
        marginTop: theme.spacing(1),
      }
    }
  },
  submenuCard: {
    [theme.breakpoints.up('lg')]: {
      borderRadius: 4,
      backgroundColor: '#fff',
      position: 'absolute',
      top: 37,
      boxShadow: theme.shadows[1],
      padding: theme.spacing(2),
      width: 390
    },
    '& > div:nth-child(1)': {
      marginTop: 0
    }
  },
  submenu: {
    paddingLeft: theme.spacing(2),
    '& > div': {
      marginTop: theme.spacing(1)
    },
    '& a': {
      padding: 6,
      textDecoration: 'none',
      color: theme.palette.text.primary,
      transition: 'all .2s ease-in-out',
      '&:hover': {
        backgroundColor: grey[200]
      }
    }
  }
}));